import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { navigate, Link } from "gatsby";
import { CloseIcon } from "../Icons";
import { SectionPageTitle } from "../Section";
import { Form, FormRow, FormCol, FormTwoCol } from "../Form";
import DefaultButton from "../Button/DefaultButton";
import StateSelect from "../StateSelect";
import styled from "styled-components";
import { Context } from "../context";

const Forms = styled(Form)`
  position: relative;
  padding: 30px 0;
  @media (min-width: 992px) {
    padding: 40px 0;
  }
  @media (min-width: 1200px) {
    padding: 50px 0;
  }
  @media (min-width: 1600px) {
    padding: 60px 0;
  }
`;

const FormHeader = styled.div`
  position: relative;
`;

const BackButton = styled(Link)`
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  &:hover {
    cursor: pointer;
  }
`;
const FormWrap = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const FormLeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`;
const FormRightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`;

const CardDefault = styled.div`
  background-color: #f6f6f6;
  color: #373636;
  height: 100%;
`;
const CardDefaultTitle = styled.h2`
  font-size: 20px;
  line-height: 30px;
  color: #373636;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`;
const CardDefaultBody = styled.div`
  padding: 30px 15px;
  @media (min-width: 992px) {
    padding: 40px 20px;
  }
  @media (min-width: 1200px) {
    padding: 50px 30px;
  }
  @media (min-width: 1600px) {
    padding: 60px 40px;
  }
`;
const PriceTable = styled.div`
  margin-bottom: 40px;
`;
const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;
const PriceThead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;
const PriceTh = styled.div`
  padding: 15px 10px;
`;
const PriceThLabel = styled.div`
  color: #373636;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
`;
const PriceCol = styled.div`
  padding: 15px 10px;
  color: #373636;
  font-size: 16px;
  line-height: 28px;
  > strong {
    font-weight: 700;
    color: #000000;
  }
`;
const PriceLabel = styled.div`
  color: #373636;
  min-width: 100px;
  font-weight: 500;
`;
const SubTotal = styled.div`
  font-weight: 700;
  color: #000;
`;

const Total = styled.div`
  font-weight: 700;
  color: #e9242a;
  font-size: 18px;
  line-height: 28px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 32px;
    line-height: 36px;
  }
`;
const PriceAction = styled.button`
  width: 100%;
  .btn {
    width: 100%;
  }
`;
const PriceDescription = styled.div`
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  p {
    font-size: 14px;
    line-height: 24px;
    > strong {
      font-weight: 500;
    }
  }
`;

const HelpText = styled.div`
  font-size: 14px;
  line-height: 24px;
  .cart-link {
    position: relative;
    color: #000;
    &:after {
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      background: rgba(233, 36, 42, 0.4);
      content: "";
      position: absolute;
      top: 110%;
      left: 0;
      width: 100%;
      height: 2px;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
    &:hover {
      color: #e9242a;
      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
`;
const TermsHeader = styled.div`
  font-size: 18px;
  line-height: 34px;
  font-weight: 700;
  padding: 5px 0;
`;
const TermsContent = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 260px;
  transition: all 0.5s;
  margin-bottom: 20px;
  h2,
  h3,
  h4 {
    text-transform: inherit;
  }
  h2 {
    font-size: 16px;
    line-height: 30px;
  }
  h3 {
    font-size: 14px;
    line-height: 24px;
  }
  h4 {
    font-size: 14px;
    line-height: 24px;
  }
  > p,
  > ul {
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }
  /* Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ebebeb;
  }

  &::-webkit-scrollbar-thumb {
    background: #6d6d6d;
  }
`;

const TermCloseBtn = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 1;
  background-color: #484b58;
  transition: all 0.5s ease-in-out;
  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }
  &:hover {
    background-color: #161420;
    svg {
      fill: #fff;
    }
  }
`;

const Terms = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 28px;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 15px;
  .form-check {
    margin: 0;
    button {
      color: #ed1c24;
      &:hover {
        color: #373636;
      }
    }
  }
`;
const CheckoutForm = ({ location = "", thanksURL }) => {
  const { cartData, setCartData } = useContext(Context);
  const [subTotal, setSubTotal] = useState();
  const [total, setTotal] = useState();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [streetaddress, setStreetAddress] = useState("");
  const [towncity, setTownCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search);
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"));
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"));
        sessionStorage.setItem(
          "utm_campaign",
          searchParams.get("utm_campaign")
        );
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"));
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"));
      }
    }
  }, []);
  const ToggleTerms = () => {
    setShowTerms(!showTerms);
  };
  const handleSubmit = (e) => {
    let utm_source_val = "Website";
    let utm_medium_val = "";
    let utm_campaign_val = "";
    let utm_term_val = "";
    let utm_content_val = "";
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source");
      utm_medium_val = sessionStorage.getItem("utm_medium");
      utm_campaign_val = sessionStorage.getItem("utm_campaign");
      utm_term_val = sessionStorage.getItem("utm_term");
      utm_content_val = sessionStorage.getItem("utm_content");
    }

    e.preventDefault();
    let orderMessage = "";
    cartData?.map((item) => {
      orderMessage += `- Product Name: ${item.title}\tProduct URL: ${
        location.origin + item.url
      }\tProduct SKU: ${item.skuNumber}\tProduct Qty: ${
        item.count
      }\tProduct Price: $${item.price * item.count}`;
    });
    if (
      firstname !== "" &&
      lastname !== "" &&
      companyname !== "" &&
      streetaddress !== "" &&
      towncity !== "" &&
      state !== "" &&
      pincode !== "" &&
      phone !== "" &&
      email !== ""
    ) {
      let formName = "place-order-form";

      const data = {
        source: location.origin,
        form: formName,
        page: location.pathname,
        first_name: firstname,
        last_name: lastname,
        address_line_1: streetaddress,
        city: towncity,
        state: state,
        zipcode: pincode,
        phone_no: phone,
        email: email,
        comment:
          "Company Name:" +
          companyname +
          "Message:" +
          message +
          "Ordered Notes: " +
          orderMessage,
        utm_medium: utm_medium_val,
        utm_term: utm_term_val,
        utm_campaign: utm_campaign_val,
        utm_content: utm_content_val,
        lead_source: utm_source_val,
      };

      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/v1/create-lead",
        data: data,
        headers: { Authorization: "Bearer " + process.env.GATSBY_API_TOKEN },
      })
        .then((res) => {
          navigate(thanksURL);
          if (typeof window !== `undefined`) {
            document.body.classList.remove("modal-open");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  useEffect(() => {
    let subTotalPrice = 0;
    cartData?.map((cartItem) => {
      subTotalPrice = subTotalPrice + cartItem.price * cartItem.count;
    });
    setSubTotal(subTotalPrice);
    setTotal(subTotalPrice);
  }, [cartData]);

  return (
    <>
      <FormWrap id="checkout" onSubmit={handleSubmit}>
        <FormLeftPanel>
          <Forms className="form">
            <FormHeader>
              <SectionPageTitle textAlign="left" className="h3">
                Billing Details
              </SectionPageTitle>
              <BackButton to="/cart/"> Back</BackButton>
            </FormHeader>
            <FormRow className="form-row">
              <FormTwoCol className="form-coltwo">
                <div className="form-group">
                  <input
                    type="text"
                    id="firstname"
                    className="form-control"
                    placeholder="First Name"
                    pattern="^(?!\s*$)[A-Za-z\s]+$"
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </FormTwoCol>
              <FormTwoCol className="form-coltwo">
                <div className="form-group">
                  <input
                    type="text"
                    id="lastname"
                    className="form-control"
                    placeholder="Last Name"
                    pattern="^(?!\s*$)[A-Za-z\s]+$"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </FormTwoCol>
              <FormTwoCol className="form-coltwo">
                <div className="form-group">
                  <input
                    type="text"
                    id="company_name"
                    className="form-control"
                    placeholder="Company Name"
                    required
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </FormTwoCol>
              <FormTwoCol className="form-coltwo">
                <div className="form-group">
                  <input
                    type="text"
                    id="street_address"
                    className="form-control"
                    placeholder="Street Address"
                    required
                    onChange={(e) => setStreetAddress(e.target.value)}
                  />
                </div>
              </FormTwoCol>
              <FormTwoCol className="form-coltwo">
                <div className="form-group">
                  <input
                    type="text"
                    id="town_city"
                    className="form-control"
                    placeholder="Town / City"
                    required
                    onChange={(e) => setTownCity(e.target.value)}
                  />
                </div>
              </FormTwoCol>
              <FormTwoCol className="form-coltwo">
                <div className="form-group form-group-select">
                  <StateSelect id="state" onChange={(e) => setState(e.value)} />
                </div>
              </FormTwoCol>
              <FormTwoCol className="form-coltwo">
                <div className="form-group">
                  <input
                    type="text"
                    id="pin_code"
                    className="form-control"
                    placeholder="Pin Code"
                    pattern="^[0-9]{5}$"
                    required
                    onChange={(e) => setPinCode(e.target.value)}
                  />
                </div>
              </FormTwoCol>
              <FormTwoCol className="form-coltwo">
                <div className="form-group">
                  <input
                    type="tel"
                    id="phone_no"
                    className="form-control"
                    placeholder="Phone Number"
                    required
                    pattern="[0-9]{10}"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </FormTwoCol>
              <FormCol>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                    className="form-control"
                    placeholder="Email ID"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </FormCol>
              <FormCol>
                <h2 className="h5">Additional Information</h2>
              </FormCol>
              <FormCol>
                <div className="form-group">
                  <textarea
                    name="message"
                    rows="3"
                    type="text"
                    id="message"
                    className="form-control"
                    placeholder="Order Notes (Optional)"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </FormCol>
            </FormRow>
          </Forms>
        </FormLeftPanel>
        <FormRightPanel>
          <div className="isSticky">
            <CardDefault>
              <CardDefaultBody>
                <CardDefaultTitle>Order Summary</CardDefaultTitle>
                <PriceTable>
                  <PriceThead>
                    <PriceTh>
                      <PriceThLabel>PRODUCT</PriceThLabel>
                    </PriceTh>
                    <PriceTh>
                      <PriceThLabel>SUBTOTAL</PriceThLabel>
                    </PriceTh>
                  </PriceThead>
                  {cartData?.map((item, i) => (
                    <PriceRow key={i}>
                      <PriceCol>
                        {item.title} <strong>X {item.count}</strong>
                      </PriceCol>
                      <PriceCol>
                        <SubTotal>
                          ${(item.price * item.count).toLocaleString("en-US")}
                        </SubTotal>
                      </PriceCol>
                    </PriceRow>
                  ))}
                  <PriceRow>
                    <PriceCol>
                      <PriceLabel>SUBTOTAL</PriceLabel>
                    </PriceCol>
                    <PriceCol>
                      <SubTotal>
                        ${parseFloat(subTotal).toLocaleString("en-US")}
                      </SubTotal>
                    </PriceCol>
                  </PriceRow>
                  <PriceRow>
                    <PriceCol>
                      <PriceLabel>TOTAL</PriceLabel>
                    </PriceCol>
                    <PriceCol>
                      <Total>
                        ${parseFloat(total).toLocaleString("en-US")}
                      </Total>
                    </PriceCol>
                  </PriceRow>
                </PriceTable>
                <PriceDescription>
                  <p>
                    <strong>
                      Thank you for ordering from EMB Metal Buildings. One of
                      our building specialists will get in touch with you
                      shortly to discuss the order details. Please be assured
                      you will NOT be charged until after our building expert
                      reaches out.
                    </strong>
                  </p>
                </PriceDescription>

                <Terms className={showTerms ? "" : "show"}>
                  {showTerms && (
                    <>
                      <TermsHeader className="terms-header">
                        Privacy Policy{" "}
                        <TermCloseBtn onClick={() => setShowTerms(false)}>
                          <CloseIcon />
                        </TermCloseBtn>
                      </TermsHeader>
                      <TermsContent className="terms-content">
                        <h2>Data Controller and Owner</h2>
                        <p>
                          EMB Metal Buildings
                          <br />
                          <a href="mailto:sales@embmetalbuildings.com.com">
                            sales@embmetalbuildings.com.com
                          </a>
                        </p>
                        <h2>Types of Data Collected</h2>
                        <p>
                          Among the types of Personal Data that this Application
                          collects, by itself or through third parties, there
                          are: Cookies, Usage data, email address, first name,
                          last name, address, password, state, ZIP/Postal code
                          and city.
                        </p>
                        <p>
                          Other Personal Data collected may be described in
                          other sections of this privacy policy or by dedicated
                          explanation text contextually with the Data
                          collection.
                        </p>
                        <p>
                          The Personal Data may be freely provided by the User,
                          or collected automatically when using this
                          Application.
                        </p>
                        <p>
                          Any use of Cookies — or of other tracking tools — by
                          this Application or by the owners of third party
                          services used by this Application, unless stated
                          otherwise, serves to identify Users and remember their
                          preferences, for the sole purpose of providing the
                          service required by the User.
                        </p>
                        <p>
                          Failure to provide certain Personal Data may make it
                          impossible for this Application to provide its
                          services.
                        </p>
                        <p>
                          Users are responsible for any Personal Data of third
                          parties obtained, published or shared through this
                          Application and confirm that they have the third
                          party’s consent to provide the Data to the Owner.
                        </p>
                        <h2>Mode and Place of Processing the Data</h2>
                        <h3>Methods of Processing</h3>
                        <p>
                          The Data Controller processes the Data of Users in a
                          proper manner and shall take appropriate security
                          measures to prevent unauthorized access, disclosure,
                          modification, or unauthorized destruction of the Data.
                        </p>
                        <p>
                          The Data processing is carried out using computers
                          and/or IT enabled tools, following organizational
                          procedures and modes strictly related to the purposes
                          indicated. In addition to the Data Controller, in some
                          cases, the Data may be accessible to certain types of
                          persons in charge, involved with the operation of the
                          site (administration, sales, marketing, legal, system
                          administration) or external parties (such as third
                          party technical service providers, mail carriers,
                          hosting providers, IT companies, communications
                          agencies) appointed, if necessary, as Data Processors
                          by the Owner. The updated list of these parties may be
                          requested from the Data Controller at any time.
                        </p>
                        <h3>Place</h3>
                        <p>
                          The Data is processed at the Data Controller’s
                          operating offices and in any other places where the
                          parties involved with the processing are located. For
                          further information, please contact the Data
                          Controller.
                        </p>
                        <h3>Retention Time</h3>
                        <p>
                          The Data is kept for the time necessary to provide the
                          service requested by the User, or stated by the
                          purposes outlined in this document, and the User can
                          always request that the Data Controller suspend or
                          remove the data.
                        </p>
                        <h3>The Use of the Collected Data</h3>
                        <p>
                          The Data concerning the User is collected to allow the
                          Owner to provide its services, as well as for the
                          following purposes: Analytics, Interaction with online
                          survey platforms, Remarketing and Behavioral
                          Targeting, Interaction with live chat platforms, Heat
                          mapping, Managing support and contact requests,
                          Registration and authentication, Content performance
                          and features testing (A/B testing), Handling payments
                          and Interaction with external social networks and
                          platforms.
                        </p>
                        <p>
                          The Personal Data used for each purpose is outlined in
                          the specific sections of this document.
                        </p>
                        <h2>
                          Detailed Information on the Processing of Personal
                          Data
                        </h2>
                        <p>
                          Personal Data is collected for the following purposes
                          and using the following services:
                        </p>
                        <h3>Analytics</h3>
                        <p>
                          The services contained in this section enable the
                          Owner to monitor and analyze web traffic and can be
                          used to keep track of User behavior.
                        </p>
                        <h4>Google Analytics (Google, Inc.)</h4>
                        <p>
                          Google Analytics is a web analysis service provided by
                          Google, Inc. (“Google”). Google utilizes the Data
                          collected to track and examine the use of this
                          Application, to prepare reports on its activities and
                          share them with other Google services. Google may use
                          the Data collected to contextualize and personalize
                          the ads of its own advertising network.
                        </p>
                        <p>Personal Data collected: Cookies and Usage data.</p>
                        <p>
                          Place of processing: US –{" "}
                          <a
                            href="https://www.google.com/intl/en/policies/privacy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>{" "}
                          –{" "}
                          <a
                            href="https://tools.google.com/dlpage/gaoptout?hl=en"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Opt Out
                          </a>
                        </p>
                        <h2>Heat Mapping</h2>
                        <p>
                          Heat Mapping services are used to display the areas of
                          a page where Users most frequently move the mouse or
                          click. This shows where the points of interest are.
                          These services make it possible to monitor and analyze
                          web traffic and keep track of User behavior.
                        </p>
                        <h3>Crazy Egg (Crazyegg)</h3>
                        <p>
                          Crazy Egg is a heat mapping service provided by Crazy
                          Egg, Inc.
                        </p>
                        <p>Personal Data collected: Cookies and Usage data.</p>
                        <p>
                          Place of processing: US –{" "}
                          <a
                            href="https://www.crazyegg.com/privacy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </p>
                        <h2>
                          Interaction with External Social Networks and
                          Platforms
                        </h2>
                        <p>
                          This type of services allow interaction with social
                          networks or other external platforms directly from the
                          pages of this Application. The interaction and
                          information obtained through this Application are
                          always subject to the User’s privacy settings for each
                          social network. This type of service might still
                          collect traffic data for the pages where the service
                          is installed, even when Users do not use it.
                        </p>
                        <h3>
                          Facebook Like Button and Social Widgets (Facebook,
                          Inc.)
                        </h3>
                        <p>
                          The Facebook Like button and social widgets are
                          services allowing interaction with the Facebook social
                          network provided by Facebook, Inc.
                        </p>
                        <p>Personal Data collected: Cookies and Usage data.</p>
                        <p>
                          Place of processing: US –{" "}
                          <a
                            href="https://www.facebook.com/privacy/explanation"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </p>
                        <h3>
                          Google+ +1 Button and Social Widgets (Google, Inc.)
                        </h3>
                        <p>
                          The Google+ +1 button and social widgets are services
                          allowing interaction with the Google+ social network
                          provided by Google Inc.
                        </p>
                        <p>Personal Data collected: Cookies and Usage data.</p>
                        <p>
                          Place of processing: US –{" "}
                          <a
                            href="https://www.google.com/intl/policies/privacy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </p>
                        <h2>Remarketing and Behavioral Targeting</h2>
                        <p>
                          This type of services allows this Application and its
                          partners to inform, optimize and serve advertising
                          based on past use of this Application by the User.
                        </p>
                        <p>
                          This activity is performed by tracking Usage Data and
                          by using Cookies, information that is transferred to
                          the partners that manage the remarketing and
                          behavioral targeting activity.
                        </p>
                        <h3>Facebook Custom Audience (Facebook, Inc.)</h3>
                        <p>
                          Facebook Custom Audience is a Remarketing and
                          Behavioral Targeting service provided by Facebook,
                          Inc. that connects the activity of this Application
                          with the Facebook advertising network.
                        </p>
                        <p>
                          Personal Data collected: Cookies and email address.
                        </p>
                        <p>
                          Place of processing: US –{" "}
                          <a
                            href="https://www.facebook.com/about/privacy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>{" "}
                          –{" "}
                          <a
                            href="https://www.aboutads.info/choices/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Opt Out
                          </a>
                        </p>
                        <h3>AdWords Remarketing (Google, Inc.)</h3>
                        <p>
                          AdWords Remarketing is a Remarketing and Behavioral
                          Targeting service provided by Google, Inc. that
                          connects the activity of this Application with the
                          Adwords advertising network and the Doubleclick
                          Cookie.
                        </p>
                        <p>Personal Data collected: Cookies and Usage data.</p>
                        <p>
                          Place of processing: US –{" "}
                          <a
                            href="http://www.google.com/intl/en/policies/privacy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>{" "}
                          –{" "}
                          <a
                            href="https://adssettings.google.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Opt Out
                          </a>
                        </p>
                        <h2>Further information about Personal Data</h2>
                        <h3>
                          The Service Is Not Directed to Children Under the Age
                          of 13
                        </h3>
                        <p>
                          Users declare themselves to be adult ing to their
                          applicable legislation. Minors may use this
                          Application only with the assistance of a parent or
                          guardian. Under no circumstance persons under the age
                          of 13 may use this Application.
                        </p>
                        <h3>Selling Goods and Services Online</h3>
                        <p>
                          The Personal Data collected is used to provide the
                          User with services or to sell goods, including payment
                          and possible delivery. The Personal Data collected to
                          complete the payment may include the credit card, the
                          bank account used for the transfer, or any other means
                          of payment envisaged. The kind of Data collected by
                          this Application depends on the payment system used.
                        </p>
                        <h2>
                          Additional Information About Data Collection and
                          Processing
                        </h2>
                        <h3>Legal Action</h3>
                        <p>
                          The User’s Personal Data may be used for legal
                          purposes by the Data Controller, in Court or in the
                          stages leading to possible legal action arising from
                          improper use of this Application or the related
                          services. The User declares to be aware that the Data
                          Controller may be required to reveal personal data
                          upon request of public authorities.
                        </p>
                        <h3>
                          Additional Information About User’s Personal Data
                        </h3>
                        <p>
                          In addition to the information contained in this
                          privacy policy, this Application may provide the User
                          with additional and contextual information concerning
                          particular services or the collection and processing
                          of Personal Data upon request.
                        </p>
                        <h3>System Logs and Maintenance</h3>
                        <p>
                          For operation and maintenance purposes, this
                          Application and any third party services may collect
                          files that record interaction with this Application
                          (System logs) or use for this purpose other Personal
                          Data (such as IP Address).
                        </p>
                        <h3>Information Not Contained in This Policy</h3>
                        <p>
                          More details concerning the collection or processing
                          of Personal Data may be requested from the Data
                          Controller at any time. Please see the contact
                          information at the beginning of this document.
                        </p>
                        <h3>The Rights of Users</h3>
                        <p>
                          Users have the right, at any time, to know whether
                          their Personal Data has been stored and can consult
                          the Data Controller to learn about their contents and
                          origin, to verify their accuracy or to ask for them to
                          be supplemented, cancelled, updated or corrected, or
                          for their transformation into anonymous format or to
                          block any data held in violation of the law, as well
                          as to oppose their treatment for any and all
                          legitimate reasons. Requests should be sent to the
                          Data Controller at the contact information set out
                          above.
                        </p>
                        <p>
                          This Application does not support “Do Not Track”
                          requests.
                        </p>
                        <p>
                          To determine whether any of the third party services
                          it uses honor the “Do Not Track” requests, please read
                          their privacy policies.
                        </p>
                        <h3>Changes to This Privacy Policy</h3>
                        <p>
                          The Data Controller reserves the right to make changes
                          to this privacy policy at any time by giving notice to
                          its Users on this page. It is strongly recommended to
                          check this page often, referring to the date of the
                          last modification listed at the bottom. If a User
                          objects to any of the changes to the Policy, the User
                          must cease using this Application and can request that
                          the Data Controller remove the Personal Data. Unless
                          stated otherwise, the then-current privacy policy
                          applies to all Personal Data the Data Controller has
                          about Users.
                        </p>
                        <h3>Information About This Privacy Policy</h3>
                        <p>
                          The Data Controller is responsible for this privacy
                          policy, prepared starting from the modules provided by
                          Tranquil Hosting and hosted on Tranquil Hosting’s
                          servers.
                        </p>
                        <h2>Definitions and Legal References</h2>
                        <h3>Personal Data (or Data)</h3>
                        <p>
                          Any information regarding a natural person, a legal
                          person, an institution or an association, which is, or
                          can be, identified, even indirectly, by reference to
                          any other information, including a personal
                          identification number.
                        </p>
                        <h3>Usage Data</h3>
                        <p>
                          Information collected automatically from this
                          Application (or third party services employed in this
                          Application), which can include: the IP addresses or
                          domain names of the computers utilized by the Users
                          who use this Application, the URI addresses (Uniform
                          Resource Identifier), the time of the request, the
                          method utilized to submit the request to the server,
                          the size of the file received in response, the
                          numerical code indicating the status of the server’s
                          answer (successful outcome, error, etc.), the country
                          of origin, the features of the browser and the
                          operating system utilized by the User, the various
                          time details per visit (e.g., the time spent on each
                          page within the Application) and the details about the
                          path followed within the Application with special
                          reference to the sequence of pages visited, and other
                          parameters about the device operating system and/or
                          the User’s IT environment.
                        </p>
                        <h3>User</h3>
                        <p>
                          The individual using this Application, which must
                          coincide with or be authorized by the Data Subject, to
                          whom the Personal Data refers.
                        </p>
                        <h3>Data Subject</h3>
                        <p>
                          The legal or natural person to whom the Personal Data
                          refers.
                        </p>
                        <h3>Data Processor (or Data Supervisor)</h3>
                        <p>
                          The natural person, legal person, public
                          administration or any other body, association or
                          organization authorized by the Data Controller to
                          process the Personal Data in compliance with this
                          privacy policy.
                        </p>
                        <h3>Data Controller (or Owner)</h3>
                        <p>
                          The natural person, legal person, public
                          administration or any other body, association or
                          organization with the right, also jointly with another
                          Data Controller, to make decisions regarding the
                          purposes, and the methods of processing of Personal
                          Data and the means used, including the security
                          measures concerning the operation and use of this
                          Application. The Data Controller, unless otherwise
                          specified, is the Owner of this Application.
                        </p>
                        <h3>This Application</h3>
                        <p>
                          The hardware or software tool by which the Personal
                          Data of the User is collected.
                        </p>
                        <h3>Cookies</h3>
                        <p>Small piece of data stored in the User’s device.</p>
                        <h3>Legal Information</h3>
                        <p>
                          Notice to European Users: this privacy statement has
                          been prepared in fulfillment of the obligations under
                          Art. 10 of EC Directive n. 95/46/EC, and under the
                          provisions of Directive 2002/58/EC, as revised by
                          Directive 2009/136/EC, on the subject of Cookies.
                        </p>
                        <p>
                          This privacy policy relates solely to this
                          Application.
                        </p>
                        <p>Latest update: August 10, 2017</p>
                        <p>
                          <a
                            href="https://www.tqhosting.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            NetActuate, Inc. D/B/A Tranquil Hosting
                          </a>{" "}
                          hosts this page and collects{" "}
                          <a
                            href="https://www.tqhosting.com/privacypolicy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            some Personal Data
                          </a>{" "}
                          about Users.
                        </p>
                      </TermsContent>
                    </>
                  )}
                  <HelpText>
                    Your personal data will only be used to process the order.
                    Please read our{" "}
                    <button
                      type="button"
                      className="cart-link"
                      onClick={ToggleTerms}
                    >
                      Privacy Policy
                    </button>{" "}
                    which will guide you on how we collect, store, and use the
                    information you provide online.
                  </HelpText>
                </Terms>
                <PriceAction type="submit" id="orderBtn">
                  <DefaultButton text="Place Order" />
                </PriceAction>
              </CardDefaultBody>
            </CardDefault>
          </div>
        </FormRightPanel>
      </FormWrap>
    </>
  );
};

export default CheckoutForm;
